import { whenIdle } from '../../util/idle';

/**
 * Lazily initialise the search autocomplete functionality, embedded in the
 * navigation header.
 *
 * Initialisation happens when the user interacts with the search input or the
 * first time the browser is idle, whichever happens first.
 *
 * This method is expected to be called once per page load.
 */
export const initialiseLazySearch = () => {
  const abortController = new AbortController();
  const { signal } = abortController;

  const input = document.querySelector('.header__search-input');

  const init = async () => {
    abortController.abort();

    const { initialiseAlgoliaAutocomplete } = await import('.');
    initialiseAlgoliaAutocomplete(
      document.querySelector('.header__search'),
      document.querySelector('.header__search-input'),
    );
  };

  input.addEventListener('input', () => { init(); }, { capture: true, once: true, signal });
  input.addEventListener('focus', () => { init(); }, { capture: true, once: true, signal });

  whenIdle(() => { init(); });
};
