import {
  headerActiveClasses,
  navigationActivatingClasses,
  navigationActiveClasses,
} from '../features/header/constants';
import {
  toggleClass,
  addClass,
  removeClass,
  removeAllActiveClasses,
} from '../features/header/util';
import {
  setQuickBasketCountFromCookie,
  initQuickBasket,
} from '../features/basket/mini-basket';
import { initialiseSwiper } from '../swiper/swiper';
import { initialiseLazySearch } from '../features/autocomplete/lazy';
import {
  getHeaderSearchInput,
  setHeaderSearchOpen,
} from '../features/autocomplete/header';

document.addEventListener('DOMContentLoaded', () => {
  // TODO move most of this into ../features/header/index.js

  const header = document.querySelector('[data-header]');
  const submenus = document.querySelectorAll('[data-submenu]');
  const toggleSubmenuButtons = document.querySelectorAll(
    '[data-toggle-submenu]'
  );
  const mobileToggle = document.querySelector('[data-mobile-toggle]');
  const secondLevelButtons = document.querySelectorAll(
    '[data-toggle-second-level]'
  );
  const searchInput = getHeaderSearchInput();

  /**
   * Toggles the submenu based on the clicked button.
   * @param {Element} clickedButton
   */
  const toggleSubmenu = clickedButton => {
    const clickedSubmenu = clickedButton.nextElementSibling;
    const isActive = clickedSubmenu?.classList.contains(
      navigationActiveClasses.groups
    );
    submenus.forEach(submenu => {
      removeClass(submenu, navigationActiveClasses.groups);
      removeClass(submenu, navigationActivatingClasses.groups);
    });
    toggleSubmenuButtons.forEach(button => {
      removeClass(button, navigationActiveClasses.button);
      button.setAttribute('aria-expanded', 'false');
    });

    removeClass(header, headerActiveClasses.submenu);

    setHeaderSearchOpen(false);

    // No fade on mobile
    const timeout = window.matchMedia('(max-width: 1024px)').matches ? 0 : 100;

    if (!isActive) {
      setTimeout(() => {
        addClass(clickedSubmenu, navigationActiveClasses.groups);
      }, timeout);
      addClass(clickedSubmenu, navigationActivatingClasses.groups);
      addClass(clickedButton, navigationActiveClasses.button);
      addClass(header, headerActiveClasses.submenu);
      clickedButton.setAttribute('aria-expanded', 'true');
    } else {
      setTimeout(() => {
        removeClass(clickedSubmenu, navigationActivatingClasses.groups);
      }, timeout);
    }
  };

  /**
   * Toggles the mobile menu.
   */
  const toggleMobileMenu = () => {
    const isMobileActive = header?.classList.toggle(headerActiveClasses.mobile);

    if (isMobileActive) {
      document.documentElement.classList.add('lock-scroll');
      closeSearch();
    } else {
      document.documentElement.classList.remove('lock-scroll');
      removeAllActiveClasses(header);
    }
  };

  /**
   * Close the search
   */
  const closeSearch = () => {
    const searchContainer = header.querySelector('.header-search');

    searchContainer.classList.remove('header-search--active');
  }

  /**
   * Toggles the second-level menu based on the clicked button.
   * @param {Element} clickedButton
   */
  const toggleSecondLevel = clickedButton => {
    toggleClass(clickedButton.parentElement, navigationActiveClasses.group);
    toggleClass(header, headerActiveClasses.secondLevel);
    clickedButton.setAttribute('aria-expanded', 'false');
  };

  /**
   * Initializes Intersection Observer to manage header position based on scrolling.
   */
  const initIntersectionObserver = () => {
    const sentinel = document.querySelector('[data-sentinel]');
    const observer = new IntersectionObserver(
      entries => {
        const position = entries[0].isIntersecting ? 'static' : 'sticky';
        header.setAttribute('data-position', position);
      },
      { threshold: 1.0 }
    );
    observer.observe(sentinel);
  };

  /**
   * Initializes a fallback scroll listener for browsers without IntersectionObserver support.
   */
  const initFallbackScrollListener = () => {
    window.addEventListener('scroll', () => {
      const position = window.scrollY > 0 ? 'static' : 'sticky';
      header.setAttribute('data-position', position);
    });
  };

  mobileToggle?.addEventListener('click', toggleMobileMenu);

  toggleSubmenuButtons.forEach(button => {
    button.addEventListener('click', e => toggleSubmenu(e.currentTarget));
  });

  secondLevelButtons.forEach(button => {
    button.addEventListener('click', e => toggleSecondLevel(e.currentTarget));
  });

  searchInput.addEventListener('keydown', e => {
    if (e.key === 'Escape') {
      e.preventDefault();
      setHeaderSearchOpen(false);
    }
  });

  /**
   * Initialize Observers and Event Listeners
   */
  if (window.IntersectionObserver) {
    initIntersectionObserver();
  } else {
    initFallbackScrollListener();
  }

  /**
   * Close header when clicking outside of the header.
   */
  document.addEventListener('click', e => {
    if (header && !header.contains(e.target)) {
      removeAllActiveClasses(header);
    }
  });

  const initNoticeCarousel = () => {
    const swiper = document.querySelector('[data-swiper]');

    initialiseSwiper(swiper);

    function manageMobileSlides(noticeSwiper, isMobile) {
      const mobileOnlySlides = document.querySelectorAll(
        '[data-mobile-only="true"]'
      );

      if (isMobile) {
        // Add mobile-only slides
        mobileOnlySlides.forEach(slide => {
          noticeSwiper.swiper.appendSlide(slide.outerHTML);
        });
      } else {
        let slidesToRemove = [];
        // Remove mobile-only slides
        noticeSwiper.swiper.slides.forEach((slide, index) => {
          if (slide.dataset.mobileOnly === 'true') {
            slidesToRemove.push(index);
          }
        });

        noticeSwiper.swiper.removeSlide(slidesToRemove);
      }

      noticeSwiper.swiper.update();
    }

    manageMobileSlides(swiper, window.innerWidth <= 1024);
  };

  initQuickBasket();
  initNoticeCarousel();
  initialiseLazySearch();
});

setQuickBasketCountFromCookie();
